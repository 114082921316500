<template>
  <div>
    <div class="router-view-container">
      <div class="flex items-center space-x-4 mb-5">
        <vs-button @click="$router.go(-1)" border size="large" class="flex-shrink-0">
          <svg-icon icon-class="arrow_left" className="primary"></svg-icon>
        </vs-button>
        <h1 class="page_title">{{ $route.meta.title }}</h1>
      </div>
      <main class="space-y-5">
        <section class="grid grid-cols-1 md:grid-cols-1 gap-6">
          <ValidationObserver  v-slot="{ invalid }" tag="div" class="box flex flex-col">
            <div class="mb-1 w-20">
              <h1 class="title">圖(80*80) <span>*</span></h1>
              <div class="upload aspect-ratio-1-1_global_style">
                <img v-if="img" :src="img" class="uploadImg" />
                <div v-else class="upload_icon">
                  <svg-icon icon-class="upload" className="gray-divide" style="width: 3rem; height: 3rem"></svg-icon>
                  <p class="text-gray-400 text-xs">點擊上傳或拖曳圖片</p>
                </div>
                <vs-input type="file" @change="uploadImg" accept="image/.png,.jpg,.jpeg,.gif,.PNG,.JPG,.JPEG,.GIF" class="img_input" />
              </div>
              <ValidationProvider mode="aggressive" name="圖" placeholder="請選擇圖" rules="required" v-slot="{ errors }" tag="div" class="relative">
                <vs-input class="w-full" v-model="img" style="display:none;"/>
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
            <div>
              <h1 class="title">標題 <span>*</span></h1>
              <ValidationProvider mode="aggressive" name="標題" placeholder="請輸入標題" rules="required|max:50" v-slot="{ errors }" tag="div" class="relative">
                <vs-input class="w-full" v-model="name" />
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>
            <div>
              <h1 class="title">描述 <span>*</span></h1>
              <ValidationProvider mode="aggressive" name="描述" placeholder="請輸入描述" rules="required|max:50" v-slot="{ errors }" tag="div" class="relative">
                <vs-input class="w-full" v-model="descs" />
                <errorDiv>{{ errors[0] }}</errorDiv>
              </ValidationProvider>
            </div>

            <div class="flex justify-between">
              <vs-button size="large" color="danger" border @click="delete_dialog = true" v-if="id != 0">
                <span class="font-medium">刪除</span>
              </vs-button>
              <div class="w-4"></div>
              <vs-button size="large" ref="saveBtn" :disabled="invalid" @click="saveBtn(invalid)">
                <span class="font-medium">儲存</span>
              </vs-button>
            </div>
            
          </ValidationObserver>
        </section>
      </main>
    </div>

    <!-- 刪除會員 dialog -->
    <vs-dialog overflow-hidden blur v-model="delete_dialog" not-center>
      <template #header>
        <h1 class="text-base font-medium text-gray-text_dark">是否要刪除{{ title }}資料？</h1>
      </template>

      <div class="mb-3">
        <p class="text-sm text-gray-text_light">刪除的{{ title }}資料無法復原，確定仍要繼續？</p>
      </div>

      <template #footer>
        <div class="flex justify-end space-x-4">
          <vs-button size="large" transparent @click="delete_dialog = false">取消</vs-button>
          <vs-button @click="deleteBtn" size="large" transparent color="danger" ref="deleteBtn">刪除</vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
export default {
  name: 'rdevice',
  data() {
    return {
      type: 2,
      fname: 'rdevices',
      title: '房間資訊',
      delete_dialog: false,
      id: 0,
      img: '',
      name: '',
      descs: '',
    }
  },
  created() {
    this.init()
  },

  methods: {
    init() {
      this.id = this.$route.params.id
      if (this.id) {
        this.$route.meta.title = `編輯${this.title}資料`
        this.loadData()
      }else{
        this.id = 0
        this.$route.meta.title = `新增${this.title}資料`
      }
    },
    loadData() {
      this.$axios({
        url: `backstage/rooms/device/${this.id}`,
        method: 'get',
        params: {
          type: this.type
        }
      }).then((res) => {
        // console.log(res)
        if (res.data.status) {
          this.is_API_loaded = true
          this.img = res.data.data.img
          this.name = res.data.data.name
          this.descs = res.data.data.descs
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.message, 'danger', 3000)
        }
      })
    },
    saveBtn(invalid) {
      if (this.id) {
        this.saveSubmit(`backstage/rooms/device/${this.id}`, 'put', invalid);
      }else{
        this.saveSubmit('backstage/rooms/device', 'post', invalid);
      }
    },
    saveSubmit(url, method, invalid) {
      if (invalid) return
      const loading = this.$vs.loading({
        target: this.$refs.saveBtn,
        background: 'primary',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })
      this.$axios({
        url: url,
        method: method,
        data: {
          type: this.type,
          img: this.img,
          name: this.name,
          descs: this.descs,
        },
      }).then((res) => {
        loading.close()
        if(res){
           if (res.data.status) {
            this.$myVS.openNoti(res.data.message, '', undefined)
            this.$router.replace({
              name: this.fname,
              query: this.$route.query
            })
          } else {
            this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.message, 'danger', 3000)
          }
        }
      })
    },
    uploadImg(e) {
      if (!e.target.files[0]) return
      if (e.target.files[0].type.indexOf('image') === -1) {
        this.$myVS.openNoti('發生錯誤，請稍候再試', '僅限圖片格式上傳', 'danger', 10000)
        return
      }
      const image = e.target.files[0]
      const reader = new FileReader()
      reader.readAsDataURL(image)
      reader.onload = (e) => {
        this.img = e.target.result
      }
      e.target.value = ''
    },
    deleteBtn() {
      const loading = this.$vs.loading({
        target: this.$refs.deleteBtn,
        background: 'danger',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })
      this.$axios({
        url: `backstage/rooms/device/${this.id}`,
        method: 'delete',
        data: {
          type: this.type,
        },
      }).then((res) => {
        loading.close() // 關閉按鈕loading
        if (res.data.status) {
          this.$myVS.openNoti(res.data.message, '', undefined)
          this.$router.replace({
            name: this.fname,
            query: this.$route.query
          })
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.message, 'danger', 3000)
        }
      })
    },
  },
}
</script>

<style scoped>
.box {
  @apply bg-white rounded-xl p-4 space-y-6;
}

.title {
  @apply text-sm font-medium text-gray-text_dark mb-1;
}

.title span{
  color: #FF0000;
}

.bigTitle {
  @apply text-base font-medium text-gray-text_dark;
}

::v-deep .vs-input__icon {
  left: 1px;
}

textarea {
  border: 1px solid #CCCCCC;
  padding: 7px 13px;
  border-radius: 16px;
  outline: none;
}

.uploadImg {
  @apply absolute top-1/2 transform -translate-y-1/2 object-contain;
  width: 100%;
  height: 100%;
}
.upload {
  @apply max-w-full border-2 rounded-xl border-dashed border-gray-200 overflow-hidden flex items-center justify-center hover:border-gray-divide transition-colors cursor-pointer relative;
}

.upload_icon {
  @apply flex flex-col items-center absolute top-1/2 transform -translate-y-1/2;
}

.img_input {
  @apply border-2 absolute z-10 h-full w-full cursor-pointer opacity-0 top-1/2 transform -translate-y-1/2;
}
</style>
